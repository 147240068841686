.imgContainer {
  position: relative;

  .loadingIndicator {
    font-size: 1.5rem;
    font-weight: 900;
    position: absolute;
    bottom: 0px;
    text-align: center;
    padding: 20px;
    width: 100%;
    background-color: #fffb;
  }
}
