@use 'styles/colors';

.container {
  max-width: 1050px;
  padding: 0 2rem;
  margin: 3rem auto 6rem;

  @media screen and (min-width: 1100px) {
    border-left: 1px solid rgba(colors.$grey, 0.3);
    border-right: 1px solid rgba(colors.$grey, 0.3);
  }

  &.fullWidth {
    border: none;
    max-width: none;
    padding: 0;
    margin: 0;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headerImage {
  width: 6rem;
  height: 6rem;
}

.headerHomeImage {
  width: 8rem;
  height: 8rem;
}

.backToHome {
  margin: 3rem 0 0;
}
